<template>
    <div style="margin-bottom:2rem;">
        <div class="flex wrap">
            <div class="col3" style="height:500px;" @click="go(index)" v-for="(item,index) in data" :key="index">
                <Item :data="item" ></Item>
            </div>

        </div>
        <More></More>
    </div>
</template>
<script>
import Item from "@/components/columnItem.vue"
import More from "@/components/More.vue"
export default {
    components:{Item,More},
    data(){
        return{
            data:[
                    {url:'case/2-1.png',title:'变造票据，这样能遮掩过去吗？',time:'2020-01-16',content:`某建筑公司与某物料公司结算用料款时，发现结算的用料数量异常，以涉嫌诈骗向公安机关报案。警方调取了物料公司留存的相关凭证，其中一张日期为“2018年6月6日”的《领料单》存在较大争议。`},
                    {url:'case/2-1-1.png',title:'身份信息被盗用，如何证明“我不是真的我”...',time:'2020-03-27',content:`前段时间，李冬先生无意间查看自己的芝麻信用评分时发现，在“信用资产”一栏中，赫然出现了一家注册地为广州的公司，而他自己则是这家公司的法定代表人，对此他竟毫不知情。`},

                ]
        }
    },
    methods:{
        go(index){
            let pathStr = '/case/trace_case/'+(index+1)
            this.$store.commit('set_path',{path:pathStr,query:{}})
        }
    }
}
</script>